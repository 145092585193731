import { gql } from '@apollo/client';

export const NOISE_EVENTS_BY_ALERT_ID = gql(`
  # This type could change in https://envirosuitelimited.atlassian.net/browse/ESTWO-5586
  # because it's not clear which type of ID can be passed in
  query GetNoiseEventsByAlertId($filter: GuidIdInFilter) {
    facility {
      alerts {
        noiseEvents(filter: $filter) {
          classifications {
            classificationId
            noiseEventId
            utcModifiedTime
          }
          comments
          id
          isRelevant
        }
      }
    }
  }
`);
