import { ClassificationState, NoiseEventsHistoryQueryVariables } from 'app/__generated__/global';
import { URL_DATE_FORMAT } from 'app/components/date-range-field';
import { useSearchParams } from 'app/hooks/use-search-params';
import { useAppSelector } from 'app/redux/store';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

type ParameterKey = (typeof PARAMETER_KEYS)[number];

type ParsedSearchParamsType = {
  page?: number;
  pageSize: number;
  from?: string;
  to?: string;
  monitor?: number[];
  classification?: string[];
  classificationState?: string;
};

const PAGE_SIZE = 12;

const PARAMETER_KEYS = ['from', 'to', 'monitor', 'classification', 'classification-state', 'page'] as const;

export const useEventsListingParams = () => {
  const { searchParams, clearSearchParams, setSearchParams } = useSearchParams<ParameterKey>([...PARAMETER_KEYS]);
  const { monitor, classification } = searchParams;
  const [page] = searchParams.page;
  const [from] = searchParams.from;
  const [to] = searchParams.to;
  const [classificationState] = searchParams['classification-state'];
  const parsedMonitorLocationIds = monitor.map(Number);
  const parsedPage = parseInt(page ?? '');
  const handleSetSearchParams = useCallback(
    (params: Parameters<typeof setSearchParams>[0]) => {
      const newParameters = Object.fromEntries(
        Object.entries(params).flatMap(([key, value]) => {
          switch (key as ParameterKey) {
            case 'monitor':
            case 'classification': {
              const existingValue = searchParams[key as ParameterKey];
              const actualNewValues = value.filter(v => !existingValue.includes(v));
              const valuesToRemove = value.filter(v => existingValue.includes(v));
              return [
                [key, [...existingValue.filter(v => !valuesToRemove.includes(v)), ...actualNewValues]],
                ['page', ['1']], // Reset to page 1 on parameter change
              ];
            }
            case 'classification-state':
            case 'from':
            case 'to': {
              return [
                [key, value],
                ['page', ['1']], // Reset to page 1 on parameter change
              ];
            }
            case 'page':
            default: {
              return [[key, value]];
            }
          }
        })
      );
      setSearchParams(newParameters);
    },
    [searchParams, setSearchParams]
  );
  const handleClearSearchParams = useCallback(
    (params: Parameters<typeof clearSearchParams>[0]) => {
      clearSearchParams(params ? [...params, 'page'] : ['page']);
    },
    [clearSearchParams]
  );
  const parsedSearchParams: ParsedSearchParamsType = useMemo(
    () => ({
      page: isNaN(parsedPage) ? undefined : parsedPage,
      pageSize: PAGE_SIZE,
      from,
      to,
      monitor: parsedMonitorLocationIds.length === 0 ? undefined : parsedMonitorLocationIds,
      classificationState,
      classification: classification.length === 0 ? undefined : classification,
    }),
    [classification, classificationState, from, parsedMonitorLocationIds, parsedPage, to]
  );
  const requestParams = useEventsListingRequestParams(parsedSearchParams);
  return useMemo(
    () => ({
      searchParams,
      requestParams,
      clearSearchParams: handleClearSearchParams,
      setSearchParams: handleSetSearchParams,
    }),
    [handleClearSearchParams, handleSetSearchParams, requestParams, searchParams]
  );
};

type RequestParams = NoiseEventsHistoryQueryVariables;

function useEventsListingRequestParams(params: ParsedSearchParamsType): RequestParams {
  const { ianaTimezone } = useAppSelector(state => state.profile.facility);
  const { page, pageSize, from, monitor, to, classificationState, classification } = params;
  const filter: RequestParams['filter'] = {};

  if (from) {
    filter.from = DateTime.fromFormat(from, URL_DATE_FORMAT, { zone: ianaTimezone }).toUTC().toISO();
  }
  if (to) {
    filter.to = DateTime.fromFormat(to, URL_DATE_FORMAT, { zone: ianaTimezone }).toUTC().toISO();
  }
  if (monitor && monitor.length > 0) {
    filter.locationIds = monitor;
  }
  if (classificationState) {
    switch (classificationState) {
      case 'classified':
        filter.classificationState = ClassificationState.Classified;
        break;
      case 'unclassified':
        filter.classificationState = ClassificationState.Unclassified;
        break;
      default:
        filter.classificationState = ClassificationState.Any;
    }
  }
  if (classification && classification.length > 0) {
    filter.classificationIds = classification;
  }
  return {
    filter,
    paging: {
      page: page ? page : 1,
      pageSize,
    },
  };
}
