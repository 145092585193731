/**
 * This function takes a number and returns it to a specified number of significant figures.
 *
 * It will keep the integer part of the number when the number of significant figures
 * is less than the number of digits in the integer part to avoid losing information.
 *
 * The function will also remove trailing zeros.
 *
 * @param value the number to be converted
 * @param significantFigures the number of significant figures to keep
 * @returns the number with the specified number of significant figures
 */
export const toSignificantFigures = (value: number, significantFigures: number) => {
  if (typeof value !== 'number') {
    return '';
  }
  if (significantFigures < 1) {
    return value.toFixed(0);
  }
  if (Math.pow(10, significantFigures) < Math.abs(value)) {
    return value.toFixed(0);
  }
  return String(parseFloat(value.toPrecision(significantFigures)));
};
