import { gql } from '@apollo/client';

// Note: recordCount cannot be accurate for this query, so preference only showing a page count
export const NOISE_EVENTS_HISTORY = gql(`
  query NoiseEventsHistory($filter: GetPagedNoiseEventsWithClassificationsFilters!, $paging: PagingInput!) {
    facility {
      noiseEvents (filter: $filter, paging: $paging) {
        previous
        next
        recordCount
        results {
          classifications {
            classificationId
          }
          comments
          id
          isRelevant
          leq
          lfMax
          sel
          locationId
          utcEndTime
          utcStartTime
          utcUpdatedTime
          windSpeed
          weighting
        }
      }
    }
  }
`);
