import { useNoiseEventClassifications } from 'app/views/configuration/pages/noise/noise-classifications/use-noise-event-classifications';
import { useMemo } from 'react';
import { NoiseEvent } from 'app/business-logic/services/noise-service';
import { EventClassificationState } from '../../components/event-classification/EventClassification';

export const useEventClassificationInitialState = ({
  event,
}: {
  event: NoiseEvent;
}): {
  isFetching: boolean;
  initialState: EventClassificationState;
} => {
  const { data, isFetching } = useNoiseEventClassifications();
  const classifications: EventClassificationState['classifications'][number][] = useMemo(
    () =>
      data?.flatMap(classification => {
        const isDeactivated = !!classification.utcDeactivatedAt;
        const isSelected = !!event.classifications?.some(c => c?.classificationId === classification.id);
        if (!isSelected && isDeactivated) return [];
        return {
          isSelected,
          classification,
        };
      }) ?? [],
    [data, event.classifications]
  );
  return useMemo(
    () => ({
      isFetching,
      initialState: {
        classifications,
        comments: event.comments ?? '',
        isRelevant: event.isRelevant ?? true,
      },
    }),
    [classifications, event, isFetching]
  );
};
